import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import OmniBox from './OmniBox';

const OmniBoxDialog = ({ open, onClose }: { open: boolean; onClose: () => void }): React.ReactElement => {
    return (
        <Dialog open={open} fullWidth maxWidth="xl" onClose={onClose}>
            <DialogContent>
                <OmniBox />
            </DialogContent>
        </Dialog>
    );
};

export default OmniBoxDialog;
