import FlightLandIcon from '@mui/icons-material/FlightLand';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import React from 'react';
import Airports from '../../data/airports.json';
import Config from '../../data/config.json';
import ActionButton from './ActionButton';

const ActionsAirport = ({ search }: { search: string }): React.ReactElement => {
    const openOurAirports = () => {
        const searchIcao = search.trim().toUpperCase();
        const ourAirportsId = Airports.filter((a: any) => a.icao === searchIcao || (a.gps !== '' && a.gps === searchIcao)).at(0);
        window.open(`https://ourairports.com/airports/${ourAirportsId?.icao}/`);
    };

    const openSkybrary = () => {
        window.open(`https://skybrary.aero/airports/${search.toLowerCase()}`);
    };

    const openAip = () => {
        window.open(`${Config.aipBaseUrl}/eAIP/EN-AD-2.${search.toUpperCase()}-no-NO.html`);
    };

    return (
        <>
            {search.toUpperCase().startsWith('EN') && (
                <ActionButton startIcon={<MenuBookIcon />} onClick={openAip}>
                    View in AIP Norway
                </ActionButton>
            )}
            <ActionButton startIcon={<LocalAirportIcon />} onClick={openOurAirports}>
                View in OurAirports
            </ActionButton>
            <ActionButton startIcon={<FlightLandIcon />} onClick={openSkybrary}>
                View in Skybrary
            </ActionButton>
        </>
    );
};

export default ActionsAirport;
