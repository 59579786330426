import { PaletteMode } from '@mui/material';
import Container from '@mui/material/Container';
import { createTheme } from '@mui/material/styles';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import React from 'react';
import useLocalStorageState from 'use-local-storage-state';
import UnhandledExceptionBoundary from './common/UnhandledExceptionBoundary';
import Footer from './Footer';
import Header from './Header';
import Routes from './Routes';

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const Layout = (): React.ReactElement => {
    const [mode, setMode] = useLocalStorageState<'light' | 'dark'>('color_theme', { defaultValue: 'dark' });

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'));
            }
        }),
        []
    );

    const theme = createTheme(
        {
            palette: {
                mode,
                primary: {
                    main: '#005282'
                },
                secondary: {
                    main: '#f50057'
                }
            },
            components: {
                MuiChip: {
                    styleOverrides: {
                        root: ({ ownerState }) => ({
                            backgroundColor: ownerState.variant === 'outlined' ? 'white' : ''
                        })
                    }
                }
            },
            mixins: {
                toolbar: {
                    background: mode === 'light' ? '#005282' : '#14314E'
                }
            }
        },
        [mode]
    );

    return (
        <UnhandledExceptionBoundary>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <Header />
                    <Container
                        component="main"
                        sx={{
                            flex: '1 0 auto',
                            paddingTop: theme.spacing(14),
                            paddingBottom: theme.spacing(6),
                            backgroundColor: theme.palette.background.default
                        }}
                        maxWidth={false}>
                        <UnhandledExceptionBoundary>
                            <Routes />
                        </UnhandledExceptionBoundary>
                    </Container>
                    <Footer />
                </ThemeProvider>
            </ColorModeContext.Provider>
        </UnhandledExceptionBoundary>
    );
};

export default Layout;
