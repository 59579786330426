import ActionButton from './ActionButton';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import RadarIcon from '@mui/icons-material/Radar';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';

const ActionsAircraftNo = ({ search }: { search: string }): React.ReactElement => {
    const openLuftfartstilsynet = () => {
        window.open(`https://luftfartstilsynet.no/aktorer/norges-luftfartoyregister/registrerte-luftfartoy/?mark=${search.toUpperCase()}`);
    };

    const openAircraftregister = () => {
        window.open(`https://www.aircraftregister.net/search?q=${search.toUpperCase()}`);
    };

    const openFr24 = () => {
        window.open(`https://www.flightradar24.com/data/aircraft/${search.toLowerCase()}`);
    };

    return (
        <>
            <ActionButton startIcon={<FactCheckIcon />} onClick={openLuftfartstilsynet}>
                View in Norges luftfartøyregister
            </ActionButton>
            <ActionButton startIcon={<SearchIcon />} onClick={openAircraftregister}>
                Search aircraftregister.net
            </ActionButton>
            <ActionButton startIcon={<RadarIcon />} onClick={openFr24}>
                View on FlightRadar24
            </ActionButton>
        </>
    );
};

export default ActionsAircraftNo;
