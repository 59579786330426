import React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import Checklist from './checklist/Checklist';
import Home from './home/Home';
import LinkLibrary from './links/LinkLibrary';
import Metar from './metar/Metar';
import EventRiskClassification from './risk/EventRiskClassification';

const Routes = (): React.ReactElement => {
    return (
        <RouterRoutes>
            <Route path="/checklist" element={<Checklist />} />
            <Route path="/risk" element={<EventRiskClassification />} />
            <Route path="/metar" element={<Metar />} />
            <Route path="/link-library" element={<LinkLibrary />} />
            <Route path="/" element={<Home />} />
        </RouterRoutes>
    );
};

export default Routes;
