import Button, { ButtonProps } from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

const ActionButton: FC<ButtonProps> = (props) => {
    const theme = useTheme();

    return (
        <Button variant="contained" sx={{ margin: theme.spacing(1) }} {...props}>
            {props.children}
        </Button>
    );
};

export default ActionButton;
