import AirIcon from '@mui/icons-material/Air';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import HomeIcon from '@mui/icons-material/Home';
import LinkIcon from '@mui/icons-material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import NumbersIcon from '@mui/icons-material/Numbers';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Header = (): React.ReactElement => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const drawerWidth = 240;

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <AppBar position="fixed">
            <Toolbar>
                <Hidden mdUp>
                    <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
                <img src="/logo.svg" alt="Logo" style={{ maxHeight: '34px', maxWidth: '90%', padding: '10px', filter: 'invert(1)' }} />
                <Typography variant="h6" sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    OBSHelp
                </Typography>
                <Hidden smDown>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton edge="start" color="inherit" aria-label="home" title="Home" sx={{ mr: 2 }} onClick={() => navigate('/')}>
                        <HomeIcon />
                    </IconButton>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="checklist"
                        title="Report Checklist"
                        sx={{ mr: 2 }}
                        onClick={() => navigate('/checklist')}>
                        <FactCheckIcon />
                    </IconButton>
                    <IconButton edge="start" color="inherit" aria-label="metar" title="Historical METAR" sx={{ mr: 2 }} onClick={() => navigate('/metar')}>
                        <AirIcon />
                    </IconButton>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="risk"
                        title="Event Risk Classification"
                        sx={{ mr: 2 }}
                        onClick={() => navigate('/risk')}>
                        <NumbersIcon />
                    </IconButton>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="link-library"
                        title="Link Library"
                        sx={{ mr: 2 }}
                        onClick={() => navigate('/link-library')}>
                        <LinkIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
            <Drawer open={drawerOpen} onClose={toggleDrawer}>
                <List sx={{ width: drawerWidth, backgroundColor: theme?.palette?.background?.paper ?? 'white' }}>
                    <ListItem>
                        <img src="/logo.svg" alt="Logo" style={{ maxHeight: '34px', maxWidth: '90%', padding: '10px' }} />
                    </ListItem>
                    <Divider />
                    <ListItem button key="home" component="a" href="/">
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button key="checklist" component="a" href="/checklist">
                        <ListItemIcon>
                            <FactCheckIcon />
                        </ListItemIcon>
                        <ListItemText primary="Report Checklist" />
                    </ListItem>
                    <ListItem button key="metar" component="a" href="/metar">
                        <ListItemIcon>
                            <AirIcon />
                        </ListItemIcon>
                        <ListItemText primary="Historical METAR" />
                    </ListItem>
                    <ListItem button key="risk" component="a" href="/risk">
                        <ListItemIcon>
                            <NumbersIcon />
                        </ListItemIcon>
                        <ListItemText primary="Event Risk Classification" />
                    </ListItem>
                    <ListItem button key="linkLibrary" component="a" href="/link-library">
                        <ListItemIcon>
                            <LinkIcon />
                        </ListItemIcon>
                        <ListItemText primary="Link Library" />
                    </ListItem>
                </List>
            </Drawer>
        </AppBar>
    );
};

export default Header;
