import { IdentifierType } from '../models/Identifiers';

export const iocPatterns = [
    {
        type: IdentifierType.AircraftRegistrationNo,
        pattern: /^LN-[A-Z]{1,3}$/i
    },
    {
        type: IdentifierType.AirportCode,
        pattern: /^[A-Z]{4}$/i
    }
];
