import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import OmniBoxDialog from './OmniBoxDialog';

const OmniBoxButton = ({ sx = undefined }: { sx: any }): React.ReactElement => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button color="info" onClick={() => setOpen(true)} sx={sx} startIcon={<SearchIcon />} variant="contained" size="small">
                Search
            </Button>
            <OmniBoxDialog open={open} onClose={() => setOpen(false)} />
        </>
    );
};

export default OmniBoxButton;
