import { Typography, useTheme } from '@mui/material';
import 'flag-icons/css/flag-icons.css';
import React, { useMemo } from 'react';
import Airports from '../../data/airports.json';
import { isoCodeToCountryName } from '../../tools/CountryTools';

const AirportNameSearch = ({ search }: { search: string }): React.ReactElement => {
    const theme = useTheme();

    const airport = useMemo(() => {
        const searchIcao = search.trim().toUpperCase();
        return Airports.filter((a: any) => a.icao === searchIcao || (a.gps !== '' && a.gps === searchIcao)).at(0);
    }, [search]);

    if (!airport) {
        return <></>;
    }

    return (
        <Typography variant="body1" sx={{ color: theme.palette.text.primary, fontStyle: 'italic' }}>
            <span className={`fi fi-${airport?.country?.toLowerCase()}`} title={isoCodeToCountryName(airport?.country)}></span> {airport?.name}
        </Typography>
    );
};

export default AirportNameSearch;
