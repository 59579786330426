import { Typography, useTheme } from '@mui/material';
import Container from '@mui/material/Container';
import React, { useEffect } from 'react';
import { setTitle } from '../../tools/SetTitle';
import MetarFetch from './MetarFetch';

const Metar = (): React.ReactElement => {
    const theme = useTheme();

    useEffect(() => {
        setTitle('Historical METAR');
    }, []);

    return (
        <Container maxWidth="xl">
            <Typography variant="h3" component="h1" gutterBottom sx={{ color: theme.palette.text.primary }}>
                Historical METAR
            </Typography>
            <MetarFetch />
            <Typography variant="subtitle2" align="left" color="textSecondary" component="p" sx={{ marginTop: theme.spacing(4), fontStyle: 'italic' }}>
                TAF/METAR from met.no
            </Typography>
        </Container>
    );
};

export default Metar;
