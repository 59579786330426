import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SchoolIcon from '@mui/icons-material/School';
import {
    Button,
    Dialog,
    DialogContent,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Snackbar,
    Typography,
    useTheme
} from '@mui/material';
import React, { useState } from 'react';
import dtoList from '../../data/dto.json';

export const DtoDialog = ({ open, onClose }: { open: boolean; onClose: () => void }): React.ReactElement => {
    const theme = useTheme();

    const [dtoCopied, setDtoCopied] = useState(false);

    const copyToClipboard = (dto: any) => {
        navigator.clipboard.writeText(`DTO: ${dto.id} ${dto.name}`);
        setDtoCopied(true);
    };

    return (
        <Dialog open={open} fullWidth maxWidth="xl" onClose={onClose}>
            <DialogContent>
                <Typography variant="h4" component="h1" gutterBottom sx={{ color: theme.palette.text.primary }}>
                    DTO list
                </Typography>
                <Typography variant="subtitle2">Click to copy DTO number/name</Typography>
                <List>
                    {dtoList.map((dto) => (
                        <ListItemButton
                            key={dto.id}
                            sx={{ padding: 0, borderBottomWidth: 1, borderBottomColor: theme.palette.divider, borderBottomStyle: 'solid' }}
                            onClick={() => copyToClipboard(dto)}>
                            <ListItemIcon>
                                <ContentCopyIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={`${dto.id} - ${dto.name}`}
                                secondary={
                                    <>
                                        {dto.courses.map((course) => (
                                            <span key={course}>
                                                &bull; {course}
                                                <br />
                                            </span>
                                        ))}
                                    </>
                                }
                            />
                            <ListItemSecondaryAction>{dto.location}</ListItemSecondaryAction>
                        </ListItemButton>
                    ))}
                </List>
                <a href="https://luftfartstilsynet.no/aktorer/utdanning/Erklaerte-flyskoler-DTO/">Erklærte flyskoler (DTO)</a>
            </DialogContent>
            <Snackbar open={dtoCopied} autoHideDuration={3000} onClose={() => setDtoCopied(false)} message="DTO copied to clipboard" color="info" />
        </Dialog>
    );
};

const DtoButton = (): React.ReactElement => {
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    return (
        <>
            <Button
                color="info"
                onClick={() => setOpen(true)}
                sx={{ marginLeft: theme.spacing(2) }}
                startIcon={<SchoolIcon />}
                variant="contained"
                size="small">
                View DTO list
            </Button>
            <DtoDialog open={open} onClose={() => setOpen(false)} />
        </>
    );
};

export default DtoButton;
