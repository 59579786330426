import AppleIcon from '@mui/icons-material/Apple';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import GoogleIcon from '@mui/icons-material/Google';
import HttpIcon from '@mui/icons-material/Http';
import LinkIcon from '@mui/icons-material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RedditIcon from '@mui/icons-material/Reddit';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import 'flag-icons/css/flag-icons.css';
import lunr from 'lunr';
import React, { useEffect, useMemo, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import LinkList from '../../data/links.json';
import { setTitle } from '../../tools/SetTitle';
import { uniqueValues } from '../../tools/UniqueValues';

const LinkLibrary = (): React.ReactElement => {
    const theme = useTheme();

    const [search, setSearch] = useState('');
    const [searching, setSearching] = useState(false);

    const [categories, setCategories] = useState<string[]>([]);
    const [categoryFilter, setCategoryFilter] = useLocalStorageState<string[]>('link_library_category_filter', {
        defaultValue: LinkList.map((l) => l.category).filter(uniqueValues)
    });

    const lunrIndex = useMemo(
        () =>
            lunr(function () {
                this.ref('id');
                this.field('title');
                this.field('category');
                this.field('description');
                this.field('tags');

                LinkList.forEach((l, id) => {
                    this.add({ id, ...l });
                }, this);
            }),
        [LinkList]
    );

    const searchResult = useMemo(() => {
        try {
            setSearching(true);
            const results = lunrIndex.search(search);
            const mappedResults = results.map((r: any) => LinkList[r.ref]);
            setSearching(false);
            return mappedResults.sort((a, b) => a.title.localeCompare(b.title));
        } catch (_e) {
            setSearching(false);
            return [];
        }
    }, [search, LinkList]);

    useEffect(() => {
        setTitle('Link Library');
    }, []);

    useEffect(() => {
        const uniqueCategories = LinkList.map((l) => l.category).filter(uniqueValues);
        setCategories(uniqueCategories);
    }, [LinkList]);

    const applyCategoryFilter = (category: string): void => {
        if (categoryFilter.includes(category)) {
            setCategoryFilter([...categoryFilter.filter((c) => c !== category)]);
        } else {
            setCategoryFilter([...categoryFilter, category]);
        }
    };

    const getTagColor = (tag: string) => {
        switch (tag) {
            case 'Information':
                return 'info';
            case 'Regulations':
                return 'secondary';
            case 'Tools':
                return 'primary';
            case 'Important':
                return 'warning';
            default:
                return undefined;
        }
    };

    const getLinkIcon = (domain: string) => {
        switch (domain) {
            case 'twitter.com':
                return <TwitterIcon color="info" />;
            case 'github.com':
            case 'github.io':
                return <GitHubIcon />;
            case 'google.com':
                return <GoogleIcon />;
            case 'apple.com':
                return <AppleIcon />;
            case 'facebook.com':
                return <FacebookIcon color="info" />;
            case 'linkedin.com':
                return <LinkedInIcon color="info" />;
            case 'reddit.com':
                return <RedditIcon color="secondary" />;
            case 'youtube.com':
                return <YouTubeIcon color="secondary" />;
            default:
                return <HttpIcon />;
        }
    };

    return (
        <Container maxWidth="xl" sx={{ paddingBottom: '10px' }}>
            <Typography variant="h3" sx={{ paddingBottom: theme.spacing(1), color: theme.palette.text.primary }}>
                <LinkIcon fontSize="large" /> Link Library
            </Typography>
            <Grid container>
                <Grid item md={12}>
                    <FormControl fullWidth>
                        <TextField label="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
                    </FormControl>
                </Grid>
                <Grid item md={12}>
                    <Stack direction="row" spacing={theme.spacing(1)} sx={{ marginTop: theme.spacing(1) }}>
                        {categories.map((category) => (
                            <Chip
                                key={category}
                                icon={categoryFilter.includes(category) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                label={category}
                                onClick={() => applyCategoryFilter(category)}
                            />
                        ))}
                    </Stack>
                </Grid>
                <Grid item md={12}>
                    {searching && <CircularProgress size="large" />}
                    <List>
                        {searchResult
                            ?.filter((l) => categoryFilter.includes(l.category))
                            ?.map((link: any, index: number) => (
                                <ListItemButton
                                    key={index}
                                    component="a"
                                    href={link.url}
                                    target="_blank"
                                    sx={{ borderBottomWidth: 1, borderBottomColor: 'lightgray', borderBottomStyle: 'solid' }}>
                                    <ListItemIcon>{getLinkIcon(link.domain)}</ListItemIcon>
                                    <ListItemText
                                        primary={link.title}
                                        secondary={
                                            <>
                                                {link.description}
                                                <Stack direction="row" spacing={theme.spacing(1)} sx={{ marginTop: theme.spacing(1) }} component="span">
                                                    {link.tags &&
                                                        link.tags?.map((t: string) => (
                                                            <Chip key={t} label={t} color={getTagColor(t)} size="small" component="span" />
                                                        ))}
                                                </Stack>
                                            </>
                                        }
                                        secondaryTypographyProps={{ sx: { maxWidth: '90%' } }}
                                        sx={{ color: theme.palette.text.primary }}
                                    />
                                    <ListItemSecondaryAction>
                                        <Chip label={link.category} />
                                    </ListItemSecondaryAction>
                                </ListItemButton>
                            ))}
                    </List>
                </Grid>
            </Grid>
        </Container>
    );
};

export default LinkLibrary;
