import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const LegalTextDialog = ({ file, open, onClose }: { file: string; open: boolean; onClose: () => void }): React.ReactElement => {
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch(`/content/${file}.md`)
            .then((r) => r.text())
            .then((r) => setContent(r));
    }, []);

    return (
        <Dialog open={open} fullWidth maxWidth="xl" onClose={onClose}>
            <DialogContent>
                <ReactMarkdown className="easa" remarkPlugins={[remarkGfm]}>
                    {content}
                </ReactMarkdown>
            </DialogContent>
        </Dialog>
    );
};

export default LegalTextDialog;
