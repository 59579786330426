/* eslint-disable @typescript-eslint/no-unused-vars */
import ClearIcon from '@mui/icons-material/Clear';
import TagIcon from '@mui/icons-material/Tag';
import {
    Button,
    ButtonGroup,
    Checkbox,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Paper,
    useTheme
} from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { setTitle } from '../../tools/SetTitle';
import LegalTextButton from '../content/LegalTextButton';
import OmniBoxButton from '../omnibox/OmniBoxButton';
import DtoButton from './DtoList';

const mandatoryFields = {
    common: [
        {
            title: 'Headline'
        },
        {
            title: 'Responsible Entity',
            description: 'Luftfartstilsynet',
            preChecked: true
        },
        {
            title: 'When - UTC Date'
        },
        {
            title: 'State/Area of Occurrence',
            description: 'Remember to use old counties, and update location indicator as well (sometimes defaults to your location)'
        },
        {
            title: 'Occurrence Category'
        },
        {
            title: 'Occurrence Class'
        },
        {
            title: 'Narrative Language'
        },
        {
            title: 'Narrative'
        },
        {
            title: 'Event Type'
        },
        {
            title: 'Risk Classification',
            tool: {
                icon: <TagIcon />,
                title: 'Event Risk Classification Calculator',
                url: '/risk'
            }
        }
    ],
    aircraft: [
        {
            title: 'Aircraft State of Registry'
        },
        {
            title: 'Aircraft Make/Model/Series'
        },
        {
            title: 'Aircraft serial number'
        },
        {
            title: 'Aircraft Registration'
        },
        {
            title: 'Aircraft Call sign (if applicable)'
        },
        {
            title: 'Aircraft Operator',
            description: 'Usually Norway - Private Operator, unless school, then Norway - Other (ATOs and other third parties might have their own operator)'
        },
        {
            title: 'Aircraft Type of Operation'
        },
        {
            title: 'Aircraft Category'
        },
        {
            title: 'Propulsion Type'
        },
        {
            title: 'Mass Group'
        },
        {
            title: 'Last Departure Point'
        },
        {
            title: 'Planned Destination'
        },
        {
            title: 'Flight Phase'
        },
        {
            title: 'Weather relevant'
        }
    ],
    atm: [
        {
            title: 'ATM contribution'
        },
        {
            title: 'Service affected (effect on ATM service)'
        },
        {
            title: 'ATS Unit Name'
        }
    ],
    airspace: [
        {
            title: 'Airspace type'
        },
        {
            title: 'Airspace class'
        },
        {
            title: 'FIR/UIR name',
            description: 'Should be ENOR'
        }
    ],
    aerodrome: [
        {
            title: 'Location Indicator (ICAO indicator of the airport)'
        },
        {
            title: 'Location on the aerodrome'
        }
    ],
    damage: [
        {
            title: 'Highest damage'
        },
        {
            title: 'Injury Level'
        },
        {
            title: 'Number of injuries on ground (fatal, serious, minor)'
        },
        {
            title: 'Number of injuries on aircraft (fatal, serious, minior)'
        }
    ]
};

const Checklist = (): React.ReactElement => {
    const theme = useTheme();

    const defaultChecked = {
        common: mandatoryFields.common.map((f) => f.preChecked ?? false),
        // @ts-ignore
        aircraft: mandatoryFields.aircraft.map((f) => f.preChecked ?? false),
        // @ts-ignore
        atm: mandatoryFields.atm.map((f) => f.preChecked ?? false),
        // @ts-ignore
        airspace: mandatoryFields.airspace.map((f) => f.preChecked ?? false),
        // @ts-ignore
        aerodrome: mandatoryFields.aerodrome.map((f) => f.preChecked ?? false),
        // @ts-ignore
        damage: mandatoryFields.damage.map((f) => f.preChecked ?? false)
    };

    const [checked, setChecked] = useLocalStorageState('checklist-checklist', { defaultValue: defaultChecked });

    useEffect(() => {
        setTitle('Report Checklist');
    }, []);

    const [isMandatory, setIsMandatory] = useLocalStorageState<boolean | undefined>('checklist-mandatory');
    const [isSchool, setIsSchool] = useLocalStorageState<boolean | undefined>('checklist-school');
    const [involvesAircraft, setInvolvesAircraft] = useLocalStorageState<boolean | undefined>('checklist-involves-aircraft', { defaultValue: true });
    const [involvesAtm, setInvolvesAtm] = useLocalStorageState<boolean | undefined>('checklist-involves-atm');
    const [involvesAirspace, setInvolvesAirspace] = useLocalStorageState<boolean | undefined>('checklist-involves-airspace');
    const [involvesAerodrome, setInvolvesAerodrome] = useLocalStorageState<boolean | undefined>('checklist-involves-aerodrome');
    const [involvesDamage, setInvolvesDamage] = useLocalStorageState<boolean | undefined>('checklist-involves-damage');

    const [_question1, setQuestion1] = useLocalStorageState<number | undefined>('risk-question1');
    const [_question2, setQuestion2] = useLocalStorageState<'effective' | 'limited' | 'minimal' | 'notEffective' | undefined>('risk-question2');

    const reset = () => {
        setIsMandatory(undefined);
        setIsSchool(undefined);
        setInvolvesAircraft(true);
        setInvolvesAtm(undefined);
        setInvolvesAirspace(undefined);
        setInvolvesAerodrome(undefined);
        setInvolvesDamage(undefined);
        setChecked(defaultChecked);

        setQuestion1(undefined);
        setQuestion2(undefined);
    };

    const toggleChecked = (category: 'common' | 'aircraft' | 'atm' | 'airspace' | 'aerodrome' | 'damage', index: number) => {
        const newValue = { ...checked };
        newValue[category][index] = !checked[category][index];
        setChecked(newValue);
    };

    const Field = ({
        field,
        category,
        index
    }: {
        field: any;
        category: 'common' | 'aircraft' | 'atm' | 'airspace' | 'aerodrome' | 'damage';
        index: number;
    }) => {
        return (
            <ListItem key={field.title}>
                <ListItemButton onClick={() => toggleChecked(category, index)} dense>
                    <ListItemIcon>
                        <Checkbox edge="start" tabIndex={-1} checked={checked[category][index]} disableRipple />
                    </ListItemIcon>
                    <ListItemText primary={field.title} secondary={field.description} />
                    {field.tool && (
                        <ListItemSecondaryAction onClick={(e) => e.stopPropagation()}>
                            <Button title={field.tool.title} component="a" href={field.tool.url} startIcon={field.tool.icon} variant="contained" color="info">
                                Open tool
                            </Button>
                        </ListItemSecondaryAction>
                    )}
                </ListItemButton>
            </ListItem>
        );
    };

    const FieldCategory = ({ title, category }: { title: string; category: 'common' | 'aircraft' | 'atm' | 'airspace' | 'aerodrome' | 'damage' }) => {
        return (
            <>
                <ListSubheader>{title}</ListSubheader>
                {mandatoryFields[category].map((f, i) => (
                    <Field key={f.title} category={category} index={i} field={f} />
                ))}
            </>
        );
    };

    return (
        <Container maxWidth={false}>
            <Typography variant="h3" component="h1" gutterBottom sx={{ color: theme.palette.text.primary }}>
                Report Checklist{' '}
                <Button onClick={() => reset()} variant="contained" color="secondary" startIcon={<ClearIcon />} sx={{ marginLeft: theme.spacing(4) }}>
                    Reset checklist
                </Button>
                <OmniBoxButton sx={{ float: 'right' }} />
            </Typography>
            <Typography variant="h4" component="h2" sx={{ marginTop: theme.spacing(4), color: theme.palette.text.primary }}>
                Step 1 - Verify if reporting is mandatory <LegalTextButton file="mandatory-reporting" sx={{}} />
            </Typography>
            <Typography variant="subtitle1" sx={{ marginBottom: theme.spacing(2), color: theme.palette.text.primary }}>
                Other types of reports we always submit: flat tires
            </Typography>

            <ButtonGroup>
                <Button variant={isMandatory ? 'contained' : 'outlined'} onClick={() => setIsMandatory(true)}>
                    Reporting is required
                </Button>
                <Button variant={!isMandatory && isMandatory !== undefined ? 'contained' : 'outlined'} onClick={() => setIsMandatory(false)}>
                    Reporting is not required
                </Button>
            </ButtonGroup>

            {isMandatory && (
                <>
                    <Typography
                        variant="h4"
                        component="h2"
                        sx={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(2), color: theme.palette.text.primary }}>
                        Step 2 - Verify and update mandatory fields <LegalTextButton file="mandatory-fields" sx={{}} />
                    </Typography>
                    <Grid container>
                        <Grid item sx={{ padding: theme.spacing(1), color: theme.palette.text.primary }}>
                            School:{' '}
                            <ButtonGroup>
                                <Button variant={isSchool ? 'contained' : 'outlined'} onClick={() => setIsSchool(true)}>
                                    Yes
                                </Button>
                                <Button variant={!isSchool && isSchool !== undefined ? 'contained' : 'outlined'} onClick={() => setIsSchool(false)}>
                                    No
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item sx={{ padding: theme.spacing(1), color: theme.palette.text.primary }}>
                            Aircraft involved:{' '}
                            <ButtonGroup>
                                <Button variant={involvesAircraft ? 'contained' : 'outlined'} onClick={() => setInvolvesAircraft(true)}>
                                    Yes
                                </Button>
                                <Button
                                    variant={!involvesAircraft && involvesAircraft !== undefined ? 'contained' : 'outlined'}
                                    onClick={() => setInvolvesAircraft(false)}>
                                    No
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item sx={{ padding: theme.spacing(1), color: theme.palette.text.primary }}>
                            ATM involved:{' '}
                            <ButtonGroup>
                                <Button variant={involvesAtm ? 'contained' : 'outlined'} onClick={() => setInvolvesAtm(true)}>
                                    Yes
                                </Button>
                                <Button variant={!involvesAtm && involvesAtm !== undefined ? 'contained' : 'outlined'} onClick={() => setInvolvesAtm(false)}>
                                    No
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item sx={{ padding: theme.spacing(1), color: theme.palette.text.primary }}>
                            Airspace-related:{' '}
                            <ButtonGroup>
                                <Button variant={involvesAirspace ? 'contained' : 'outlined'} onClick={() => setInvolvesAirspace(true)}>
                                    Yes
                                </Button>
                                <Button
                                    variant={!involvesAirspace && involvesAirspace !== undefined ? 'contained' : 'outlined'}
                                    onClick={() => setInvolvesAirspace(false)}>
                                    No
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item sx={{ padding: theme.spacing(1), color: theme.palette.text.primary }}>
                            Aerodrome involved:{' '}
                            <ButtonGroup>
                                <Button variant={involvesAerodrome ? 'contained' : 'outlined'} onClick={() => setInvolvesAerodrome(true)}>
                                    Yes
                                </Button>
                                <Button
                                    variant={!involvesAerodrome && involvesAerodrome !== undefined ? 'contained' : 'outlined'}
                                    onClick={() => setInvolvesAerodrome(false)}>
                                    No
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item sx={{ padding: theme.spacing(1), color: theme.palette.text.primary }}>
                            Any damage:{' '}
                            <ButtonGroup>
                                <Button variant={involvesDamage ? 'contained' : 'outlined'} onClick={() => setInvolvesDamage(true)}>
                                    Yes
                                </Button>
                                <Button
                                    variant={!involvesDamage && involvesDamage !== undefined ? 'contained' : 'outlined'}
                                    onClick={() => setInvolvesDamage(false)}>
                                    No
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>

                    <Paper sx={{ marginTop: theme.spacing(3) }}>
                        {isSchool && (
                            <Typography sx={{ padding: theme.spacing(2) }}>
                                Remember to set Operator to <strong>Norway - Other</strong> and add correct DTO to the end of the Narrative
                                <DtoButton />
                            </Typography>
                        )}
                        <List>
                            <FieldCategory category="common" title="Common fields" />
                            {involvesAircraft && <FieldCategory category="aircraft" title="Aircraft specific fields" />}
                            {involvesAtm && <FieldCategory category="atm" title="ATM specific fields" />}
                            {involvesAirspace && <FieldCategory category="airspace" title="Airspace specific fields" />}
                            {involvesAerodrome && <FieldCategory category="aerodrome" title="Aerodrome specific fields" />}
                            {involvesDamage && <FieldCategory category="damage" title="Damage specific fields" />}
                        </List>
                    </Paper>

                    <Typography variant="h4" component="h2" sx={{ marginTop: theme.spacing(4), color: theme.palette.text.primary }}>
                        Step 3 - Submit report to CAA
                    </Typography>
                    <Paper sx={{ marginTop: theme.spacing(3), padding: theme.spacing(2) }}>
                        <ul>
                            <li>Save</li>
                            <li>Send E5X</li>
                            <li>Ensure that the report has finished processing</li>
                        </ul>
                    </Paper>
                </>
            )}

            <Typography variant="h4" component="h2" sx={{ marginTop: theme.spacing(4), color: theme.palette.text.primary }}>
                Step 4 - Send report to club for further processing
            </Typography>
            <Paper sx={{ marginTop: theme.spacing(3), padding: theme.spacing(2) }}>
                <ul>
                    <li>Remove DTO from narrative</li>
                    <li>Send report to FTL for further processing</li>
                </ul>
            </Paper>
        </Container>
    );
};

export default Checklist;
