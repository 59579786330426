import { Card, CardContent, CardHeader, Grid, Snackbar, Typography, useTheme } from '@mui/material';
import Container from '@mui/material/Container';
import React, { useEffect, useMemo, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { setTitle } from '../../tools/SetTitle';

const riskMatrix = [
    {
        name: 'No Accident outcome',
        description: 'No potential damage or injury could occur',
        effective: 1,
        limited: 1,
        minimal: 1,
        notEffective: 1
    },
    {
        name: 'Minor Injuries or damage',
        description: 'Minor injuries, minor damage to aircraft',
        effective: 2,
        limited: 3,
        minimal: 20,
        notEffective: 100
    },
    {
        name: 'Major Accident',
        description: 'Serious injuries, major damage to the aircraft',
        effective: 10,
        limited: 21,
        minimal: 101,
        notEffective: 500
    },
    {
        name: 'Catastrophic Accident',
        description: 'Loss of aircraft',
        effective: 50,
        limited: 102,
        minimal: 502,
        notEffective: 2500
    }
];

const question2Alternatives = [
    { name: 'Effective', key: 'effective' },
    { name: 'Limited', key: 'limited' },
    { name: 'Minimal', key: 'minimal' },
    { name: 'Not Effective', key: 'notEffective' }
];

const EventRiskClassification = (): React.ReactElement => {
    const theme = useTheme();

    const [question1, setQuestion1] = useLocalStorageState<number | undefined>('risk-question1');
    const [question2, setQuestion2] = useLocalStorageState<'effective' | 'limited' | 'minimal' | 'notEffective' | undefined>('risk-question2');

    const [riskCopied, setRiskCopied] = useState(false);

    const riskScore = useMemo(
        () => (question1 !== undefined && question2 !== undefined ? riskMatrix[question1 ?? 0][question2 ?? 'effective'] : ''),
        [question1, question2]
    );
    const riskColor = useMemo(
        // @ts-ignore
        () => (riskScore >= 500 ? theme.palette.error.main : riskScore >= 20 ? theme.palette.warning.main : theme.palette.success.main),
        [riskScore]
    );

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`Event Risk Classification: ${riskScore ?? ''}`);
        setRiskCopied(true);
    };

    useEffect(() => {
        setTitle('Event Risk Classification');
    }, []);

    return (
        <Container maxWidth="xl">
            <Typography variant="h3" component="h1" gutterBottom sx={{ color: theme.palette.text.primary }}>
                Event Risk Classification
            </Typography>
            <Typography variant="h5" component="h2" sx={{ color: theme.palette.text.primary }}>
                If this event had escalated into an accident, what would have been the most probable outcome?
            </Typography>
            <Grid container>
                {riskMatrix.map((x, i) => (
                    <Grid key={x.name} item md={3} sx={{ padding: theme.spacing(2) }}>
                        <Card
                            sx={{ height: 160, cursor: 'pointer', backgroundColor: question1 === i ? theme.palette.action.selected : undefined }}
                            onClick={() => setQuestion1(i)}
                            elevation={question1 === i ? 6 : 1}>
                            <CardHeader title={x.name} sx={{ textAlign: 'center' }} />
                            <CardContent sx={{ textAlign: 'center' }}>{x.description}</CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Typography variant="h5" component="h2" sx={{ marginTop: theme.spacing(6), color: theme.palette.text.primary }}>
                What was the effectiveness of the remaining barriers between this event and the most probable accident scenario?
            </Typography>
            <Grid container>
                {question2Alternatives.map((x) => (
                    <Grid key={x.key} item md={3} sx={{ padding: theme.spacing(2) }}>
                        <Card
                            sx={{ height: 65, cursor: 'pointer', backgroundColor: question2 === x.key ? theme.palette.action.selected : undefined }}
                            onClick={() =>
                                // @ts-ignore
                                setQuestion2(x.key)
                            }
                            elevation={question2 === x.key ? 6 : 1}>
                            <CardHeader title={x.name} sx={{ textAlign: 'center' }} />
                        </Card>
                    </Grid>
                ))}
            </Grid>
            {riskScore && (
                <>
                    <Card
                        sx={{
                            marginTop: theme.spacing(10),
                            backgroundColor: riskColor,
                            color: theme.palette.common.white,
                            fontWeight: 'bold',
                            cursor: 'pointer'
                        }}
                        title="Click to copy"
                        onClick={() => copyToClipboard()}>
                        <CardContent sx={{ textAlign: 'center', fontSize: '300%' }}>{riskScore}</CardContent>
                    </Card>
                    <Typography sx={{ textAlign: 'right', fontStyle: 'italic' }} variant="subtitle2" onClick={() => copyToClipboard()}>
                        Click to copy
                    </Typography>
                </>
            )}
            <Snackbar
                open={riskCopied}
                autoHideDuration={3000}
                onClose={() => setRiskCopied(false)}
                message="Event Risk Classification copied to clipboard"
                color="info"
            />
        </Container>
    );
};

export default EventRiskClassification;
