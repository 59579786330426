import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Alert, Button, Card, CardContent, CardHeader, CircularProgress, IconButton, Snackbar, Stack, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import MetarPlots from './MetarPlots';

const MetarFetch = (): React.ReactElement => {
    const theme = useTheme();

    const [airports, setAirports] = useState<string[]>([]);
    const [date, setDate] = useState<any>(null);
    const [departure, setDeparture] = useState<any>(null);
    const [arrival, setArrival] = useState<any>(null);
    const [fetchingMetar, setFetchingMetar] = useState(false);
    const [metar, setMetar] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [warning, setWarning] = useState<string | null>(null);

    const [metarCopied, setMetarCopied] = useState(false);

    const handleErrors = async (response: any) => {
        if (!response.ok) {
            const responseText = await response.text();
            throw Error(responseText);
        }
        return response;
    };

    const fetchMetar = async () => {
        setFetchingMetar(true);
        setError(null);
        setWarning(null);

        await fetch(`https://obshelp.limanovember.aero/api/met/metar?icao=${airports.join('%2C')}&date=${dayjs(date).format('YYYY-MM-DD')}`)
            .then(handleErrors)
            .then((result) => result.text())
            .then((result) => {
                if (!departure && !arrival) {
                    setMetar(result);

                    if (!result || result.trim().length === 0) {
                        setWarning('No METARs found, check valid station, date, and departure/arrival time');
                    }
                    return;
                }

                const dep = departure ? departure : dayjs().hour(0).minute(0);
                const arr = arrival ? arrival : dayjs().hour(23).minute(14);

                const from = dep.format('HH') === '00' ? 0 : parseInt(dep.add(-30, 'minute').format('HHmm'), 10);
                let to = parseInt(dayjs(arr).add(45, 'minute').format('HHmm'), 10);

                if (to < from) {
                    to = 2359;
                }

                const filtered = result
                    .split('\n')
                    .filter((m: string) => m.trim().length > 0)
                    .filter((m: string) => {
                        const metarTime = parseInt(m.split(' ')[1].slice(2, 6), 10);
                        return metarTime >= from && metarTime <= to;
                    });

                let metarResult = null;

                if (filtered.length === 0) {
                    const all = result.split('\n').filter((m: string) => m.trim().length > 0);
                    metarResult = airports
                        .map((airport) =>
                            all
                                .filter((m: string) => m.startsWith(airport))
                                .join('\n')
                                .trim()
                        )
                        .filter((x) => x.trim().length > 0)
                        .join('\n\n');
                } else {
                    metarResult = airports
                        .map((airport) =>
                            filtered
                                .filter((m: string) => m.startsWith(airport))
                                .join('\n')
                                .trim()
                        )
                        .filter((x) => x.trim().length > 0)
                        .join('\n\n');
                }

                setMetar(metarResult);

                if (!metarResult || metarResult.trim().length === 0) {
                    setWarning('No METARs found, check valid station, date, and departure/arrival time');
                }
            })
            .catch((e) => {
                setError(e.message.replace('400 Bad Request', '').trim());
            });

        setFetchingMetar(false);
    };

    const copyMetar = () => {
        navigator.clipboard.writeText(metar ?? '');
        setMetarCopied(true);
    };

    return (
        <>
            <Paper sx={{ padding: theme.spacing(2), marginTop: theme.spacing(3) }}>
                <Stack direction="row" spacing={theme.spacing(1)}>
                    <TextField label="Airports" value={airports.join(' ')} onChange={(e) => setAirports(e.target.value.toUpperCase().split(' '))} />
                    <DatePicker
                        label="Date"
                        value={date}
                        onChange={(newValue: any) => {
                            setDate(newValue);
                        }}
                    />
                    <TimePicker
                        label="Departure time UTC"
                        value={departure}
                        ampm={false}
                        onChange={(newValue: any) => {
                            setDeparture(newValue);
                        }}
                    />
                    <TimePicker
                        label="Arrival time UTC"
                        value={arrival}
                        ampm={false}
                        onChange={(newValue: any) => {
                            setArrival(newValue);
                        }}
                    />
                    <Button variant="outlined" onClick={fetchMetar} disabled={fetchingMetar || !date || airports.length === 0}>
                        {fetchingMetar ? <CircularProgress /> : 'Fetch'}
                    </Button>
                </Stack>
            </Paper>
            {error && (
                <Alert severity="error" sx={{ marginTop: theme.spacing(3) }}>
                    {error}
                </Alert>
            )}
            {warning && (
                <Alert severity="warning" sx={{ marginTop: theme.spacing(3) }}>
                    {warning}
                </Alert>
            )}
            {metar && (
                <Card sx={{ marginTop: theme.spacing(3) }}>
                    <CardHeader
                        title={
                            <>
                                Historical METARs
                                <IconButton size="medium" title="Copy to clipboard" onClick={copyMetar} sx={{ float: 'right' }}>
                                    <ContentCopyIcon fontSize="inherit" />
                                </IconButton>
                            </>
                        }
                    />
                    <CardContent>
                        <pre style={{ marginTop: 0, marginBottom: theme.spacing(5), paddingTop: 0, paddingBottom: 0 }}>{metar}</pre>
                        {metar.length > 0 && <MetarPlots metars={metar} />}
                    </CardContent>
                </Card>
            )}
            <Snackbar open={metarCopied} autoHideDuration={3000} onClose={() => setMetarCopied(false)} message="METARs copied to clipboard" color="info" />
        </>
    );
};

export default MetarFetch;
