import { Grid, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { setTitle } from '../../tools/SetTitle';
import OmniBox from '../omnibox/OmniBox';
import HomeLauncher from './HomeLauncher';

const Home = (): React.ReactElement => {
    const theme = useTheme();

    useEffect(() => {
        setTitle('Home');
    }, []);

    return (
        <Container maxWidth="lg" sx={{ paddingBottom: theme.spacing(2) }}>
            <Grid container>
                <Grid item md={12}>
                    <Typography variant="h3" sx={{ paddingBottom: theme.spacing(1), color: theme.palette.text.primary }}>
                        Welcome to OBSHelp!
                    </Typography>
                    <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
                        - the helper tool for NLF OBSREG
                    </Typography>
                </Grid>
                <Grid item md={12} sx={{ marginTop: theme.spacing(3) }}>
                    <OmniBox />
                </Grid>
                <Grid item md={12} sx={{ marginTop: theme.spacing(3) }}>
                    <HomeLauncher />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Home;
