import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Snackbar, Typography, useTheme } from '@mui/material';
import 'flag-icons/css/flag-icons.css';
import React, { useMemo, useState } from 'react';
import DtoList from '../../data/dto.json';
import ActionButton from './ActionButton';

const DtoSearch = ({ search }: { search: string }): React.ReactElement => {
    const theme = useTheme();

    const dto = useMemo(() => {
        const searchDto = search.trim().toLowerCase();

        if (searchDto.length < 3) {
            return undefined;
        }

        return DtoList.filter((d: any) => d.name.toLowerCase().includes(searchDto) || d.location.toLowerCase().includes(searchDto)).at(0);
    }, [search]);

    const [dtoCopied, setDtoCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`DTO: ${dto?.id} ${dto?.name}`);
        setDtoCopied(true);
    };

    if (!dto) {
        return <></>;
    }

    return (
        <>
            <Typography variant="body1" sx={{ color: theme.palette.text.primary, fontStyle: 'italic' }}>
                {dto.id} {dto?.name}
            </Typography>
            <ActionButton startIcon={<ContentCopyIcon />} onClick={copyToClipboard}>
                {' '}
                Kopier DTO
            </ActionButton>
            <Snackbar open={dtoCopied} autoHideDuration={3000} onClose={() => setDtoCopied(false)} message="DTO copied to clipboard" color="info" />
        </>
    );
};

export default DtoSearch;
