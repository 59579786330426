import GavelIcon from '@mui/icons-material/Gavel';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import ContentDialog from './ContentDialog';

const LegalTextButton = ({ file, sx = undefined }: { file: string; sx: any }): React.ReactElement => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button color="info" onClick={() => setOpen(true)} sx={sx} title="Click to view rules" startIcon={<GavelIcon />} variant="contained" size="small">
                View rules
            </Button>
            <ContentDialog file={file} open={open} onClose={() => setOpen(false)} />
        </>
    );
};

export default LegalTextButton;
