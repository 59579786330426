import AirIcon from '@mui/icons-material/Air';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import LinkIcon from '@mui/icons-material/Link';
import NumbersIcon from '@mui/icons-material/Numbers';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LaunchIcon from '@mui/icons-material/Launch';

const launcherItems = [
    {
        title: 'Report Checklist',
        link: '/checklist',
        icon: FactCheckIcon
    },
    {
        title: 'Historical METAR',
        link: '/metar',
        icon: AirIcon
    },
    {
        title: 'Event Risk Classification',
        link: '/risk',
        icon: NumbersIcon
    },
    {
        title: 'Link Library',
        link: '/link-library',
        icon: LinkIcon
    },
    {
        title: 'OBSREG',
        link: 'https://app.nlf.no/',
        external: true,
        icon: LaunchIcon
    },
    {
        title: 'OBSREG (Dev)',
        link: 'https://dev.nlf.no/',
        external: true,
        icon: LaunchIcon
    }
];

const HomeLauncher = (): React.ReactElement => {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Grid container>
            {launcherItems.map((item) => (
                <Grid key={item.title} item md={4} padding={theme.spacing(3)}>
                    <Card
                        onClick={() => (item.external ? window.open(item.link, '_blank') : navigate(item.link))}
                        sx={{
                            cursor: 'pointer',
                            ':hover': {
                                boxShadow: theme.shadows[20]
                            }
                        }}>
                        <CardContent>
                            <Box alignItems="center" justifyContent="center" height={200} display="flex" flexDirection="column">
                                <Icon component={item.icon} fontSize="large" />
                                <Typography variant="h5">{item.title}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default HomeLauncher;
